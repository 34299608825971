import enquire from 'enquire.js'

(function() {

	function run() {
		var $button = $('.button-vote--category');
		/* VOTE BUTTON */
		$button.on('click', function() {
			var self = $(this);
			if (self.hasClass('is-active')) {
				self.removeClass('is-active');
				self.find('label').text('Dát hlas');
				self.find('input').prop('checked', false);
			} else {
				self.parent().parent().parent().find('.moto-item .button-vote--category').removeClass('is-active').find('input').prop('checked', false);
				self.addClass('is-active');
				self.find('label').text('Vybráno');
				self.find('input').prop('checked', true);
			}
		});

		$('.fb-share-button').on('click', function(event) {
			event.preventDefault();
			var href = $(this).attr('href');
			window.open(href, 'fbshare', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=700,height=400')
		});
	}

	run();
	$(window).on('update-form-scripts', run);

	/* MOBILE CAROUSEL */
	enquire.register("screen and (max-width: 47.9375em)", {
		match : function() {
			$('.motorcycles:not(.no-slick)').slick({
				centerMode: true,
				arrows: false,
				infinite: false,
				variableWidth: true,
				lazyLoad: 'progressive'
			});
		}
	});

})();
