(function () {

    const section = document.getElementById('favorite-section');
    const items = document.querySelectorAll('[data-favorite-item]');
    const categoryVoteButtons = document.querySelectorAll('[data-contestant-id]');
    const favoriteVoteButtons = document.querySelectorAll('[data-favorite-button]');

    let data;
    let contestantData;
    let selectedIds = [null,null,null,null,null,null];

    function init() {
        categoryVoteButtons.forEach((button) => assignCategoryAction(button));
        favoriteVoteButtons.forEach((button) => assignFavoriteAction(button));
    }

    function assignCategoryAction(button) {
        const $button = $(button);

        $button.on('click', function() {
			    setCategoryVote(this.getAttribute('data-contestant-category'),this.getAttribute('data-contestant-id'));
		    });
    }

    function assignFavoriteAction(button) {
        const $button = $(button);

        $button.on('click', function() {
          setFavoriteVote(this);
        });
    }

    function setCategoryVote(contestantCategory,contestantId) {
        items.forEach((item) => refreshItem(item,contestantCategory,contestantId));
    }

    function setFavoriteVote(activeButton) {
        favoriteVoteButtons.forEach((button) => resetButton(button));

        activeButton.classList.add("is-active");
        activeButton.querySelector('label').innerHTML = "Vybráno";
        activeButton.querySelector('input').checked = true;
        
    }

    function resetButton(button) {
        button.classList.remove("is-active");
        button.querySelector('label').innerHTML = "Zvolit";
    }

    function refreshItem(item,contestantCategory,contestantId) {
        if(contestantCategory === item.getAttribute('data-favorite-item')){            
            contestantData = getContestantData(contestantId);

            if(selectedIds[contestantCategory] == contestantId){
              selectedIds[contestantCategory] = null;
            } else {
              selectedIds[contestantCategory] = contestantId;
            }

            item.querySelector('[data-favorite-name]').innerHTML = contestantData[0];
            item.querySelector('[data-favorite-company]').innerHTML = contestantData[1];
            item.querySelector('[data-favorite-logo]').className  = "brand-logo logo-" + contestantData[2];
            item.querySelector('[data-favorite-image]').src = "assets/frontend/img/motorcycles2024/" + contestantData[3];
            item.querySelector('input').value = contestantId;

            render();
        }
    }

    function render() {
      if(!selectedIds.includes(null)){
        section.classList.add("is-active");
      } else {
        section.classList.remove("is-active");
      }
    }

    function getContestantData(contestantId) {
        switch(contestantId) {
            case "1":
                data = ['PEUGEOT XP400','Asko KC s.r.o.','peugeot','peugeot_xp400.jpg'];
              break;
            case "2":
                data = ['QJMOTOR SRT 800 SX','Asko KC s.r.o.','qjmotor','srt_800sx.jpg'];
              break;
            case "3":
                data = ['QJMOTOR SVT 650 X','Asko KC s.r.o.','qjmotor','svt_650x.jpg'];
              break;
            case "4":
                data = ['QJMOTOR SRV 600 V','Asko KC s.r.o.','qjmotor','srv_600v.jpg'];
              break;
            case "5":
                data = ['FANTIC CABALLERO 700','Asko KC s.r.o.','fantic','fantic_caballero_700.jpg'];
              break;
            case "6":
                data = ['QJMOTOR SRK 125 S','Asko KC s.r.o.','qjmotor','srk_125s.jpg'];
              break;
            case "7":
                data = ['QJMOTOR SRK 800RR','Asko KC s.r.o.','qjmotor','srk_800_rr.JPG'];
              break;
            case "8":
                data = ['BMW CE 02','BMW Česká republika','bmw','bmw_ce_02.jpg'];
              break;
            case "9":
                data = ['BMW R 1300 GS','BMW Česká republika','bmw','bmw_r_1300_gs.jpg'];
              break;
            case "10":
                data = ['BMW R 12','BMW Česká republika','bmw','bmw_r_12.jpg'];
              break;
            case "11":
                data = ['BMW S 1000 XR','BMW Česká republika','bmw','bmw_s_1000_xr.jpg'];
              break;
            case "12":
                data = ['BMW M 1000 XR','BMW Česká republika','bmw','bmw_m_1000_xr.jpg'];
              break;
            case "13":
                data = ['CLS T-ROX125i','CLS DEAL s.r.o.','cls','cls_t-rox-125i.jpg'];
              break;
            case "14":
                data = ['VESPA PRIMAVERA','FABER Gmbh','faber','vespa_primavera.jpg'];
              break;
            case "15":
                data = ['MOTO GUZZI STELVIO','FABER Gmbh','faber','faber_moto_guzzi_stelvio.jpg'];
              break;
            case "16":
                data = ['APRILIA RS 457','FABER Gmbh','faber','aprilia_rs_457.jpg'];
              break;
            case "17":
                data = ['VOGE SR1 125i GT','Gorex s.r.o.','voge','voge_sr1_125i_gt.jpg'];
              break;
            case "18":
                data = ['VOGE 900 DSX','Gorex s.r.o.','voge','voge_900_dsx.jpg'];
              break;
            case "19":
                data = ['VOGE 525 DSX','Gorex s.r.o.','voge','valico_525dsx.jpg'];
              break;
            case "20":
                data = ['HONDA CRF1100L AFRICA TWIN ADVENTURE SPORTS','Honda Motor Europe Ltd.','honda','crf1100l_africa_twin_adventure_sports.jpg'];
              break;
            case "21":
                data = ['HONDA CB500 Hornet','Honda Motor Europe Ltd.','honda','cb_500_hornet.jpg'];
              break;
            case "22":
                data = ['HONDA CBR600RR','Honda Motor Europe Ltd.','honda','honda_cbr_600_rr.jpg'];
              break;
            case "23":
                data = ['CFMOTO 450MT','JOURNEYMAN CZ s.r.o.','cfmoto','cfmoto_450mt.jpg'];
              break;
            case "24":
                data = ['CFMOTO 450SR-S','JOURNEYMAN CZ s.r.o.','cfmoto','cfmoto_450sr_s.jpg'];
              break;
            case "25":
                data = ['KTM 1390 Super Duke R','KTM Central East Europe s.r.o. ','ktm','ktm_1390_super_duke_r.jpg'];
              break;
            case "26":
                data = ['SUZUKI V-STROM 800','MAGYAR SUZUKI CORP., BRANCH OFFICE CZECH REPUBLIC','suzuki','suzuki_v_strom_800.jpg'];
              break;
            case "27":
                data = ['SUZUKI GSX-8S','MAGYAR SUZUKI CORP., BRANCH OFFICE CZECH REPUBLIC','suzuki','suzuki_gsx_8s.jpg'];
              break;
            case "28":
                data = ['SUZUKI GSX-S1000GX','MAGYAR SUZUKI CORP., BRANCH OFFICE CZECH REPUBLIC','suzuki','suzuki_gsx_s1000gx.jpg'];
              break;
            case "29":
                data = ['SUZUKI GSX-8R','MAGYAR SUZUKI CORP., BRANCH OFFICE CZECH REPUBLIC','suzuki','suzuki_gsx_8r.jpg'];
              break;
            case "30":
                data = ['SYM ADX 125','Nevima Group s.r.o.','nevimagroup','sym_adx_125.jpg'];
              break;
            case "31":
                data = ['MALAGUTI MADISON CITY 125 ','Nevima Group s.r.o.','nevimagroup','malaguti_madison_city_125.jpg'];
              break;
            case "32":
                data = ['BENELLI TRK 702X','Nevima Group s.r.o.','nevimagroup','studio_trk_702x.jpg'];
              break;
            case "33":
                data = ['SWM SUPERDUAL 600','Nevima Group s.r.o.','nevimagroup','swm_super_dual_t_dx.jpg'];
              break;
            case "34":
                data = ['MBP C1002V','Nevima Group s.r.o.','nevimagroup','mbp_c1002v.jpg'];
              break;
            case "35":
                data = ['BRIXTON CROMWELL 1200X','Nevima Group s.r.o.','nevimagroup','brixton_cromwell_1200_x.jpg'];
              break;
            case "36":
                data = ['TRIUMPH Tiger 900','TRIUMPH-DK auto-moto s.r.o.','triumph','triumph_tiger_900.jpg'];
              break;
            case "37":
                data = ['TRIUMPH Scrambler 400X','TRIUMPH-DK auto-moto s.r.o.','triumph','triumph_scrambler_400x.jpg'];
              break;
            case "38":
                data = ['TRIUMPH Speed 400','TRIUMPH-DK auto-moto s.r.o.','triumph','triumph_speed_400.jpg'];
              break;
            case "39":
                data = ['Royal Enfield HIMALAYAN 450','Vintage GarageMotors, a.s.','royal-enfield','himalayan_450.jpg'];
              break;
            case "40":
                data = ['Royal Enfield SHOTGUN 650','Vintage GarageMotors, a.s.','royal-enfield','shotgun650.jpg'];
              break;
            case "41":
                data = ['YAMAHA MT-09 SP','Yamaha Motor Europe N.V.','yamaha','yamaha_mt_09.jpg'];
              break;
            case "42":
                data = ['YUKI 125i SE','PYRAMIDA Průhonice s.r.o.','yuki','yuki_125i_se.jpg'];
              break;
            case "43":
                data = ['Harley-Davidson® Low Rider™ ST','HARLEY-DAVIDSON Eastern Europe','harley','hd_low_rider_st.jpg'];
              break;
            case "44":
                data = ['Harley-Davidson® Street Glide®','HARLEY-DAVIDSON Eastern Europe','harley','hd_street_glide.jpg'];
              break;
            case "45":
                data = ['Harley-Davidson® CVO™ Road Glide® ST','HARLEY-DAVIDSON Eastern Europe','harley','hd_cvo_road_glide_st.jpg'];
              break;
            case "46":
                data = ['Harley-Davidson® CVO™ Pan America™','HARLEY-DAVIDSON Eastern Europe','harley','hd_cvo_pan_america.jpg'];
              break;
            case "47":
                data = ['DUCATI Multistrada V4 RS','MOTO ITALIA s.r.o.','ducati','ducati_multistrada_v4_rs.jpg'];
              break;
            case "48":
                data = ['INDIAN CHIEF BOBBER DARK HORSE','Meteor Motor Tech CR, s.r.o.','indian','indian_chief_bobber_dark_horse.jpg'];
              break;
            case "49":
                data = ['INDIAN PURSUIT LIMITED','Meteor Motor Tech CR, s.r.o.','indian','indian_pursit_limited.jpg'];
          }

        return data;
    }

    init();
})();
