(function() {

    $.nette.ext('form-vote', {
        success: function(payload) {
            if (payload.message.success == "Hlas odeslán") {
                dataLayer.push({"event": "formSent", "form": "vote"});

                document.getElementById('odhlasovano').style="display: block;";
                document.getElementById('hlasovani').style="display: none;";
            }
        }
    });

})();