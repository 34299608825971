// lib
// import './lib/modernizr.js'
import Nette from './lib/netteForms.js'
import './lib/nette.ajax.js'

$.nette.init();

// plugins
// import './plugins/_anijs-min'
// import './plugins/anijs-helper-scrollreveal'
import './plugins/slick'

import './modules/form-vote'
import './modules/form'
import './modules/giant'
import './modules/logos'
import './modules/motorcycle'
import './modules/nette'
import './modules/orange-line'
import './modules/prizes'

import './components/FavoriteSection'