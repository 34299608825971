(function() {
	function run() {
		if ($('.category-line .line').length) {
			var $line = $('.category-line .line');
			var $overlay = $('.category-line .line-overlay');
			var lineTop = $line.offset().top;
			var currentPos = $(window).scrollTop();
			var windowHeight = $(window).height();
			var screenMiddle = currentPos + windowHeight / 2;
			var lineEnd = $('.category-line .line-overlay').offset().top + $('.category-line .line-overlay').height();

			if (screenMiddle < lineTop) {
				$line.css('height', 0);
			} else if (screenMiddle > lineTop && screenMiddle < lineEnd) {
				var height = screenMiddle - lineTop;
				$line.css('height', height);
			} else {
				$line.css('bottom', '0');
			}
		}
	}

	run();
	$(window).scroll(run);
	$(window).on('update-form-scripts', run);

})();
