(function () {


    $('#more-prizes').on('click', function (event) {
        event.preventDefault();
        $('.prizes-other').slideToggle();
        $(this).toggleClass('is-open');
        $(this).attr('class') === 'is-open' ? $(this).text('Skrýt další ceny') : $(this).text('Zobrazit dalších 95 cen');
    });


})();