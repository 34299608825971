import enquire from 'enquire.js'

(function() {

	enquire.register("screen and (max-width:48em)", {

		match : function() {

			$('.partners-headline').on('click', function(event) {
				event.preventDefault();
				$(this).next().slideToggle(300);
				$(this).toggleClass('opened');
			});
		}

	});

})();