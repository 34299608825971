(function() {

	var $input = $('.input-special');

	function run() {
		/* SPECIAL INPUT */
		$input.find('input').on('focus', function() {
			$(this).parent().addClass('input-active');
		});
		$input.find('input').on('blur', function() {
			if ($(this).val() === "") {
				$(this).parent().removeClass('input-active');
			}
		});

		/* SHOWING FIELDSETS */
		$('#radio-1, #radio-2').on('change', function() {
			if ($('#radio-1').prop('checked')) {
				$('#motorcycle-details').slideDown(200);
			} else {
				$('#motorcycle-details').slideUp(200);
			}
		});

		$('#radio-3, #radio-4').on('change', function() {
			if ($('#radio-3').prop('checked')) {
				$('#motorcycle-details-2').slideDown(200);
			} else {
				$('#motorcycle-details-2').slideUp(200);
			}
		});

		$('#radio-7, #radio-8').on('change', function() {
			if ($('#radio-7').prop('checked')) {
				$('#motorcycle-details-3').slideDown(200);
			} else {
				$('#motorcycle-details-3').slideUp(200);
			}
		});

		/* radio buttons and checkboxes */
		$('.custom-checkbox input, .custom-radio input').on('change', function() {
			if ($(this).prop('checked')) {
				$(this).parent().addClass('active');
			} else {
				$(this).parent().removeClass('active');
			}
		});
	}

	run();
	$(window).on('update-form-scripts', run);

})();
