import Nette from '../lib/netteForms'

/**
 * Validates whole form.
 */
Nette.validateForm = function(sender) {
  var form = sender.form || sender, scope = false;
  if (form['nette-submittedBy'] && form['nette-submittedBy'].getAttribute('formnovalidate') !== null) {
    var scopeArr = Nette.parseJSON(form['nette-submittedBy'].getAttribute('data-nette-validation-scope'));
    if (scopeArr.length) {
      scope = new RegExp('^(' + scopeArr.join('-|') + '-)');
    } else {
      return true;
    }
  }

  var radios = {}, i, elem, valid = true, focused;

  for (i = 0; i < form.elements.length; i++) {
    elem = form.elements[i];

    if (elem.type === 'radio') {
      if (radios[elem.name]) {
        continue;
      }
      radios[elem.name] = true;
    }

    if ((scope && !elem.name.replace(/]\[|\[|]|$/g, '-').match(scope)) || Nette.isDisabled(elem)) {
      continue;
    }

    if (!Nette.validateControl(elem)) {
      valid = false;
      if (!focused) {
        focused = elem;
        $("html, body").animate({
          scrollTop: $(elem).parent().offset().top},
        100, function() {
          if (elem.focus) {
            elem.focus();
          }
        });
      }
    }
  }

  $('.button-error-box').removeClass('error');
  $('.button-error-box').find('.error-message').remove();

  if (!valid) {
    var message = 'Nevyplnili jste všechny potřebné údaje.';
    $('.button-error-box')
      .addClass('error')
      .prepend('<span class="error-message">' + message + '</span>');
  }
  return valid;
};


Nette.addError = function(elem, message) {
	var elem = $(elem);

	if (elem.length) {
      elem.parent().find('.error-message').remove();
	  	elem.parent().addClass('error');
	  	elem.parent().append('<span class="error-message">' + message + '</span>');
	}


  if (elem.attr('type') === 'radio' || elem.attr('type') === 'checkbox') {
    elem.on('change', function() {
      $(this).parent().removeClass('error');
    });
  } else {
    elem.change(function() {
      $(this).parent().removeClass('error');
      $(this).parent().find('.error-message').remove();
    });
  }

};

$.nette.ext('modal', {
	success: function (payload) {

	  	if (payload.message.error) {

	  		$('.main-error').show().text(payload.message.error);

	  		return;

	  	} else {
		  	var duration = 500;

		  	$('.message-sent').fadeIn(duration);

		  	if (payload.message.success) {
			  	setTimeout(function() {
				    window.scrollTo(0, 0);
			    }, duration);
		  	}

		    setTimeout(function() {
		    	$('.message-sent').fadeOut(duration);
		    }, 5000);

            dataLayer.push({
                "event": "formSent",
                "form": "vote"
            });

            $(window).trigger('update-form-scripts');

	    }

	},
});